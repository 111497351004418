import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { ILoggedIn } from '../../../models/dtos/get/login.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedUserService {
  private _login: BehaviorSubject<ILoggedIn | null> = new BehaviorSubject<ILoggedIn | null>(
    null!
  );

  public loggedIn$: Observable<ILoggedIn | null> = this._login.asObservable();

  constructor(private _localStorageService: LocalstorageService<ILoggedIn>) { }

  public get() {
    let login: ILoggedIn | null = this._localStorageService.getItem('loggedIn');
    if (!login) {

    } else {
      this._login.next(login);
    }
  }

  public set(login: ILoggedIn | null) {
    this._login.next(login);
  }
}
